<template>
  <div>
    <nav v-if="authenticated" class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/tests">
          <img alt="Torc" data-src="/logo.svg" src="/logo.svg">
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-content"
          aria-controls="navbar-content"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar-content">
          <ul class="navbar-nav me-auto mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/tests">
                Tests
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/login" v-if="!authenticated">
                Login
              </router-link>
            </li>
            <li class="nav-item">
              <a href="" class="nav-link" v-if="authenticated" v-on:click="logout()">
                Logout
              </a>
            </li>
          </ul>
          <span class="navbar-nav nav-link disabled" v-if="authenticated">
            {{claims.email}}
          </span>
        </div>
      </div>
    </nav>
    <div class="container-md" id="content">
      <router-view/>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Warning!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Your session has expired, unsaved changes will be lost. Please refresh the page to log in again.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: 'app',
  data() {
    return {
      authenticated: false,
      claims: ''
    };
  },
  async mounted() {
    this.sessionExpirationModal = new Modal(document.getElementById('exampleModal'), {
      keyboard: true
    });
  },
  async created() {
    await this.isAuthenticated();
    this.$auth.authStateManager.subscribe(this.isAuthenticated);
    this.$auth.tokenManager.on('expired', (key) => {
      if (key === 'accessToken') {
        this.sessionExpirationModal.show();
      }
    });
  },
  watch: {
    // Everytime the route changes, check for auth status
    $route: 'isAuthenticated'
  },
  methods: {
    async isAuthenticated() {
      this.authenticated = await this.$auth.isAuthenticated();
      this.showEmail();
    },
    async logout() {
      await this.$auth.signOut();
    },
    async showEmail() {
      if (this.$root.authenticated) {
        this.claims = await this.$auth.getUser();
      }
    },
    dismissModal() {
      this.sessionExpirationModal.hide();
    }
  }
};
</script>

<style lang="scss">
nav div a { margin-right: 10px }
</style>
