export default {
  version: 'rev 2021-05 v3.2',
  sections: [
    {
      id: 'post-trip-report-header-section',
      headerSection: true,
      fields: [
        {
          id: 'post-report-version',
          model: 'version',
          label: 'Report Version',
          type: 'readonly'
        },
        {
          id: 'ops-manager',
          model: 'opsManager',
          label: 'Ops Manager',
          type: 'text',
          isData: true
        },
        {
          id: 'odometer',
          model: 'odometer',
          label: 'Odometer',
          type: 'text',
          isData: true
        },
        {
          id: 'safety-conductor',
          model: 'safetyConductor',
          label: 'SC',
          type: 'text',
          isData: true
        },
        {
          id: 'total-miles',
          model: 'totalMiles',
          label: 'Total Miles',
          type: 'text',
          isData: true
        },
        {
          id: 'safety-driver',
          model: 'safetyDriver',
          label: 'SD',
          type: 'text',
          isData: true
        },
        {
          id: 'testId',
          model: 'id',
          label: 'Test ID',
          type: 'readonly',
          isData: false
        },
        {
          id: 'passengers',
          model: 'passengers',
          label: 'Passenger(s)',
          type: 'text',
          isData: true
        },
        {
          id: 'software',
          model: 'software',
          label: 'Software',
          type: 'text',
          isData: true
        },
        {
          id: 'vehicle-id',
          model: 'vehicle_id',
          label: 'Vehicle ID',
          type: 'text',
          isData: false
        },
        {
          id: 'date',
          model: 'date',
          label: 'Date',
          type: 'text',
          isData: true
        },
        {
          id: 'location',
          model: 'location',
          label: 'Location',
          type: 'text',
          isData: true
        },
        {
          id: 'time',
          model: 'time',
          label: 'Time',
          type: 'text',
          isData: true
        },
        {
          id: 'route',
          model: 'route',
          label: 'Route',
          type: 'text',
          isData: true
        },
        {
          id: 'post-report-sign-off',
          model: 'scSignOff',
          label: 'SC Sign-off',
          type: 'text',
          isData: true
        }
      ]
    },
    {
      id: 'post-trip-report-did-you-section',
      headerSection: false,
      header: 'Did You?',
      fields: [
        {
          id: 'check-shore-power',
          model: 'shorePowerChecked',
          label: 'Plug in shore power?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-emo-button',
          model: 'emoButtonChecked',
          label: 'Engage EMO button?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-clean-logger',
          model: 'cleanLoggerChecked',
          label: 'Exchange logging node for clean node?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-offloading',
          model: 'offloadingChecked',
          label: 'Ensure data is offloading in node room?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-vehicle-secured',
          model: 'vehicleSecured',
          label: 'Ensure vehicle is secure?',
          type: 'boolean',
          isData: true
        }
      ]
    },
    {
      id: 'post-trip-report-info-section',
      headerSection: false,
      header: 'Report Information',
      subsections: [
        {
          id: 'test-description-sub-section',
          header: 'Test Description',
          fields: [
            {
              id: 'test-description',
              model: 'testDescription',
              type: 'text',
              isData: true
            }
          ]
        },
        {
          id: 'environmental-observations-sub-section',
          header: 'Environmental Observations (Weather, Road, etc.)',
          fields: [
            {
              id: 'environmental-observations',
              model: 'environmentalObservations',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'safety-debrief-sub-section',
          header: 'Safety Debrief',
          fields: [
            {
              id: 'felt-unsafe',
              model: 'feltUnsafe',
              type: 'text',
              label: 'Did anyone feel unsafe?',
              isData: true
            },
            {
              id: 'protocol-changes',
              model: 'protocolChanges',
              type: 'text',
              label: 'Should we change anything about our safety protocols?',
              isData: true
            }
          ]
        },
        {
          id: 'operational-delays-section',
          header: 'Operational Delays',
          fields: [
            {
              id: 'operational-delays',
              model: 'operationalDelays',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'technical-delays-section',
          header: 'Technical Delays',
          fields: [
            {
              id: 'technical-delays',
              model: 'technicalDelays',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'technical-debrief',
          header: 'Technical Debrief',
          fields: [
            {
              id: 'unplanned-takeovers',
              model: 'unplannedTakeovers',
              label: 'Number of unplanned takeovers:',
              type: 'text',
              isData: true
            },
            {
              id: 'robotic-kickouts',
              model: 'rkos',
              label: 'Number of robotic kickouts:',
              type: 'text',
              isData: true
            },
            {
              id: 'unplanned-takeovers-description',
              model: 'unplannedTakeoversDesc',
              label: 'Summary of unplanned takeovers:',
              type: 'richtext',
              isData: true
            },
            {
              id: 'rko-description',
              model: 'rkoDesc',
              label: 'Summary of RKOs:',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'comments-concerns',
          header: 'Comments/Concerns',
          fields: [
            {
              id: 'ads-takeaways',
              model: 'adsTakeaways',
              label: 'Key ADS takeaways (summary of what went well and what can be improved, answers to any questions in test ticket):',
              type: 'richtext',
              isData: true
            },
            {
              id: 'truck-issues',
              model: 'truckIssues',
              label: 'Base Truck Issues:',
              type: 'richtext',
              isData: true
            },
            {
              id: 'additional-comments',
              model: 'comments',
              label: 'Additional overall comments about the test:',
              type: 'richtext',
              isData: true
            }
          ]
        }
      ]
    }
  ]
};
