<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyCustomUploadAdapterPlugin from '../lib/uploadadapter';

const fieldTemplate = (
  field,
  form,
  isHeaderSection,
  editor,
  testId,
  auth,
  autoSave,
  readOnlyMode,
  missingFields
) => {
  const baseModel = field.isData ? form.data : form;

  let reqStatus = '';
  if (missingFields && missingFields.includes(field.model)) {
    reqStatus = 'is-invalid';
  }

  if (field.type === 'radio') {
    return (
      <div class="col form-radio form-control" class={ reqStatus }>
        <label class="form-label" vHtml={ field.label }></label>
        <br/>
        <div class="btn-group" role="group" aria-label={ field.label }>
          {
            field.choices.map((choice) => (
              [
                <input type="radio" class="btn-check" id={ choice.id } value={ choice.value } vModel={ baseModel[field.model] } autocomplete="off" />,
                <label class="btn btn-outline-primary" for={ choice.id } vHtml={ choice.label }></label>
              ]
            ))
          }
        </div>
      </div>
    );
  }

  if (field.type === 'info') {
    return (
      <div class="col col-sm-12 info">
        <i vHtml={field.message}></i>
      </div>
    );
  }

  if (field.type === 'delayCategory') {
    return (
      <div class="col col-sm-6">
        <div class="row">
          <label class="form-label col-form-label" for={ field.id } vHtml={ field.label }></label>
        </div>
        <div class="row">
          <div class="col">
            <input class="form-control" class={ reqStatus } type="number" min="0" id={ field.id } vModel={ baseModel[field.model] } disabled={ readOnlyMode } />
          </div>
          <div class="col delay-measure">
            <span>minutes</span>
          </div>
        </div>
      </div>
    );
  }

  if (field.type === 'weightCategory' && (field.model === 'datePickedUp' || field.label === 'Date')) {
    return (
      <div class="col col-sm-2">
        <div class="row">
          <label class="form-label col-form-label" for={ field.id } vHtml={ field.label }></label>
        </div>
        <div class="row">
          <div class="col">
            <input type="date" id={ field.id } vModel={baseModel[field.model]} class="form-control" class={ reqStatus } disabled={ readOnlyMode } />
          </div>
        </div>
      </div>
    );
  }

  if (field.type === 'weightCategory' && field.label === 'Time') {
    return (
      <div class="col col-sm-2">
        <div class="row">
          <label class="form-label col-form-label" for={ field.id } vHtml={ field.label }></label>
        </div>
        <div class="row">
          <div class="col">
            <input type="time" id={ field.id } vModel={baseModel[field.model]} class="form-control" class={ reqStatus } disabled={ readOnlyMode } />
          </div>
        </div>
      </div>
    );
  }

  if (field.type === 'weightCategory') {
    return (
      <div class="col col-sm-2">
        <div class="row">
          <label class="form-label col-form-label" for={ field.id } vHtml={ field.label }></label>
        </div>
        <div class="row">
          <div class="col">
            <input type="number" id={ field.id } vModel={baseModel[field.model]} class="form-control" class={ reqStatus } disabled={ readOnlyMode } />
          </div>
        </div>
      </div>
    );
  }

  if (field.type === 'boolean') {
    return (
      <div class="col form-check">
        <input class="form-check-input" class={ reqStatus } type="checkbox" id={field.id} vModel={baseModel[field.model]} disabled={ readOnlyMode } />
        <label class="form-check-label" for={field.id} vHtml={ field.label }></label>
      </div>
    );
  }

  if (field.type === 'textarea') {
    const label = field.label ? (<label class="form-label" for={field.id}>{field.label}</label>) : '';
    return (
      <div class="col">
        {label}
        <textarea class="form-control" class={ reqStatus } id={field.id} rows="5" vModel={baseModel[field.model]} disabled={ readOnlyMode }></textarea>
      </div>
    );
  }

  if (field.type === 'readonly') {
    return [
      (<label class="form-label col-form-label col-md-2" for={ field.id }>{ field.label }</label>),
      (
        <div class="col col-md-4">
          <input type="text" id={ field.id } vModel={baseModel[field.model]} class="form-control" readonly />
        </div>
      )
    ];
  }

  if (field.type === 'richtext') {
    const editorConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'indent',
          'outdent',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'undo',
          'redo'
        ]
      },
      extraPlugins: [MyCustomUploadAdapterPlugin],
      uploadAdapter: {
        testId,
        auth
      }
    };
    const elements = [
      (<label class="form-label col-form-label" for={ field.id }>{ field.label }</label>)
    ];
    if (autoSave) {
      elements.push(
        (
          <div class="col">
            <ckeditor
              disabled={ readOnlyMode }
              editor={ editor }
              vModel={ baseModel[field.model] }
              config={ editorConfig }
              onInput={ autoSave }>
            </ckeditor>
          </div>
        )
      );
    } else {
      elements.push(
        (
          <div class="col">
            <ckeditor
              disabled={ readOnlyMode }
              editor={ editor }
              vModel={ baseModel[field.model] }
              config={ editorConfig }>
            </ckeditor>
          </div>
        )
      );
    }
    return elements;
  }

  let labelClass = 'form-label col-form-label';
  let inputClass = 'col';

  if (isHeaderSection) {
    labelClass = `${labelClass} col-md-2`;
    inputClass = `${inputClass} col-md-4`;
  }
  const label = field.label ? (<label class={labelClass} for={ field.id }>{ field.label }</label>) : '';
  return [
    label,
    (
      <div class={inputClass}>
        <input type={ field.type } id={ field.id } vModel={baseModel[field.model]} class="form-control" class={ reqStatus } disabled={ readOnlyMode } />
      </div>
    )
  ];
};

export default {
  data() {
    return {
      editor: ClassicEditor
    };
  },
  render() {
    if (!this.formTemplate) {
      return (<div></div>);
    }
    return this.sections();
  },
  props: ['form', 'formTemplate', 'autoSave', 'readOnlyMode', 'missingFields'],
  emits: ['formRequiredField'],
  methods: {
    sections() {
      return this.formTemplate.sections.map((section) => this.sectionTemplate(section));
    },
    sectionTemplate(section) {
      if (section.headerSection) {
        return this.headerSection(section);
      }

      if (section.footerSection) {
        return (
          <div class="row" id={ section.id }>
            <div class="col">
              { section.fields.map((field) => fieldTemplate(
                field,
                this.form,
                false,
                this.editor,
                this.$route.params.testId,
                this.$auth,
                this.autoSave,
                this.readOnlyMode,
                this.missingFields
              ))
              }
            </div>
          </div>
        );
      }
      return this.section(section);
    },
    headerSection(section) {
      return (
        <div class="row header-section" id={ section.id }>
          <div class="col">
            { this.headerSectionFields(section.fields) }
          </div>
        </div>
      );
    },
    headerSectionFields(fields) {
      const rows = [];
      let currentRow = 0;
      let fieldsInRow = 0;
      fields.forEach((field) => {
        if (field.isRequired) {
          this.$emit('formRequiredField', field.model);
        }
        if (fieldsInRow === 0) {
          rows[currentRow] = { fields: [] };
        }
        rows[currentRow].fields.push(field);
        fieldsInRow += 1;
        if (fieldsInRow === 2) {
          currentRow += 1;
          fieldsInRow = 0;
        }
      });
      return rows.map((row) => (
        <div class="row">
          { row.fields.map((field) => fieldTemplate(
            field,
            this.form,
            true,
            this.editor,
            this.$route.params.testId,
            this.$auth,
            this.autoSave,
            this.readOnlyMode,
            this.missingFields
          )) }
        </div>
      ));
    },
    section(section) {
      if (section.subsections) {
        return (
          <div class="row report-section" id={ section.id }>
            <div class="col">
              <div class="row justify-content-center section-header">
                <div class="col col-md-2">{ section.header }</div>
              </div>
              { section.subsections.map((subsection) => (this.subsection(subsection))) }
            </div>
          </div>
        );
      }
      return (
        <div class="row report-section" id={ section.id }>
          <div class="col">
            <div class="row justify-content-center section-header">
              <div class="col col-md-2">{ section.header }</div>
            </div>
            { section.fields.map((field) => (
                <div class="row">
                  { fieldTemplate(
                    field,
                    this.form,
                    false,
                    this.editor,
                    this.$route.params.testId,
                    this.$auth,
                    this.autoSave,
                    this.readOnlyMode,
                    this.missingFields
                  ) }
                </div>
            ))
            }
          </div>
        </div>
      );
    },
    subsection(subsection) {
      return [
        (
          <div class="row sub-section-header" id={ subsection.id }>
            <div class="col">
              { subsection.header }
            </div>
          </div>
        ),
        this.subsectionFields(subsection.delaySection, subsection.loadWeightsSection, subsection.fields, subsection.id)
      ];
    },
    subsectionFields(isDelaySection, isLoadWeightsSection, fields, subsectionId) {
      fields.forEach((field) => {
        if (field.isRequired) {
          this.$emit('formRequiredField', field.model);
        }
      });
      if (isDelaySection || isLoadWeightsSection) {
        const rows = [];
        let currentRow = 0;
        let fieldsInRow = 0;
        fields.forEach((field) => {
          if (fieldsInRow === 0) {
            rows[currentRow] = { fields: [] };
          }
          rows[currentRow].fields.push(field);

          if (field.type === 'info') {
            fieldsInRow = 2;
          } else {
            fieldsInRow += 1;
          }

          if (fieldsInRow === 2 && isDelaySection) {
            currentRow += 1;
            fieldsInRow = 0;
          }
        });
        return rows.map((row) => (
          <div class={`row ${subsectionId}`}>
            { row.fields.map((field) => fieldTemplate(
              field,
              this.form,
              false,
              this.editor,
              this.$route.params.testId,
              this.$auth,
              this.autoSave,
              this.readOnlyMode,
              this.missingFields
            )) }
          </div>
        ));
      }
      return [
        ...fields.map((field) => (
          <div class={`row ${subsectionId}`}>
            { fieldTemplate(
              field,
              this.form,
              false,
              this.editor,
              this.$route.params.testId,
              this.$auth,
              this.autoSave,
              this.readOnlyMode,
              this.missingFields
            ) }
          </div>
        ))
      ];
    }
  }
};
</script>
