<template>
  <div class="loading-overlay" v-if="initialLoad">
    <div class="uil-ring-css" style="transform:scale(0.79);">
      <div></div>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <div id="empty-delay-reports-message" v-if="!hasDelayReports">Add some delays to start reporting. All fields must be filled out completely and correctly.</div>
      <form id="truck_delay_form" class="border-bottom pt-4" v-for="(truckDelayReport, index) in truckDelayReports" :key="index">
        <div class="row">
          <label class="col col-md-2">Vehicle ID <small>(required)</small>:</label>
          <div class="col col-md-4">
            <select id="vehicle-id" class="form-select" required v-model="truckDelayReport.vehicle_id" @input="save()">
              <option value="Fleet">Fleet</option>
              <option value="C1">C1</option>
              <option value="C2">C2</option>
              <option value="C3">C3</option>
              <option value="C4">C4</option>
              <option value="C5">C5</option>
              <option value="C6">C6</option>
              <option value="C7">C7</option>
              <option value="C8">C8</option>
              <option value="C9">C9</option>
              <option value="C10">C10</option>
              <option value="C11">C11</option>
              <option value="C12">C12</option>
              <option value="C13">C13</option>
              <option value="C14">C14</option>
              <option value="C15">C15</option>
              <option value="C16">C16</option>
              <option value="C17">C17</option>
              <option value="C18">C18</option>
              <option value="C19">C19</option>
              <option value="C20">C20</option>
              <option value="C21">C21</option>
              <option value="C22">C22</option>
              <option value="C23">C23</option>
              <option value="C24">C24</option>
              <option value="C25">C25</option>
              <option value="C26">C26</option>
              <option value="C27">C27</option>
              <option value="C28">C28</option>
              <option value="F1">F1</option>
              <option value="F2">F2</option>
              <option value="P1">P1</option>
              <option value="P2">P2</option>
              <option value="Subaru Outback">Subaru Outback</option>
              <option value="Ford Explorer">Ford Explorer</option>
              <option value="Dodge Charger">Dodge Charger</option>
              <option value="Ford Fusion">Ford Fusion</option>
            </select>
          </div>
        </div>
        <div class="row">
          <label class="col col-md-2">Location <small>(required)</small>:</label>
          <div class="col col-md-4">
            <select id="location" class="form-select" required v-model="truckDelayReport.location" @input="save()">
              <option value="ABQ">ABQ</option>
              <option value="AUS">AUS</option>
              <option value="BCB">BCB</option>
              <option value="MAD">MAD</option>
              <option value="Other">Other</option>
              <option value="All">All</option>
            </select>
          </div>
        </div>
        <div class="row">
          <label class="col col-md-2">Delay Start Date <small>(required)</small>:</label>
          <div class="col col-md-4"><input id="delay_start_date" type="date" class="form-control" required v-model="truckDelayReport.delay_start_date" @input="save()" /></div>
        </div>
        <div class="row">
          <label class="col col-md-2">Delay Duration Minutes <small>(required)</small>:</label>
          <div class="col col-md-4"><input id="delay_duration_minutes" type="number" min="0" class="form-control"
              v-model="truckDelayReport.delay_duration_minutes" required @input="save()" /></div>
        </div>
        <div class="row">
          <label class="col col-md-2">Delay Category <small>(required)</small>:</label>
          <div class="col col-md-4">
            <select id="delay_category_primary" v-model="truckDelayReport.delay_category_primary" @change="truckDelayReport.delay_category_secondary = ''" class="form-select"
              required @input="save()">
              <option value="ADS" selected>ADS</option>
              <option value="Base Truck">Base Truck</option>
              <option value="Logistical">Logistical</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="col col-md-4">
            <select id="delay_category_secondary" v-model="truckDelayReport.delay_category_secondary" class="form-select"
              required @input="save()">
              <template v-if="truckDelayReport.delay_category_primary === 'ADS'">
                <option>Lidar</option>
                <option>Radar</option>
                <option>Camera</option>
                <option>Pinpoint and Antennas</option>
                <option>Alpha</option>
                <option>Bravo</option>
                <option>Logger</option>
                <option>Canister and Centauri</option>
                <option>OCU tablet</option>
                <option>Deployment software</option>
                <option>Calibration</option>
                <option>Map</option>
                <option>Bootstrap</option>
                <option>Software Performance</option>
                <option>Multi-Vehicle Tool</option>
                <option>Electrical</option>
                <option>Wifi and Craddlepoint</option>
              </template>
              <template v-else-if="truckDelayReport.delay_category_primary === 'Base Truck'">
                <option>Air Conditioning</option>
                <option>Active Power Steering (APS)</option>
                <option>Brake</option>
                <option>Engine</option>
                <option>Transmission</option>
                <option>Electronic Control Unit (ECU) and Dash Warnings</option>
                <option>Trailer</option>
                <option>Cab Tires</option>
                <option>Auto Parts</option>
              </template>
              <template v-else-if="truckDelayReport.delay_category_primary === 'Logistical'">
                <option>Operations Status Order (OSO)</option>
                <option>Test Progression Plan (TPP) and Operational Design Domain (ODD) Restrictions</option>
                <option>Test Crew Readiness</option>
                <option>Traffic and Accidents</option>
                <option>Process</option>
              </template>
              <template v-else-if="truckDelayReport.delay_category_primary === 'Other'">
                <option>None</option>
              </template>
            </select>
          </div>
        </div>
        <div class="row">
          <label class="col col-md-2">Description <small>(required)</small>:</label>
          <div class="col col-md-8">
            <textarea id="description" class="form-control" v-model="truckDelayReport.description" @input="save()" />
          </div>
        </div>
        <div class="row">
          <div class="col col-6">
            <button data-testid="remove-delay" type="button" class="btn btn-danger" v-on:click="removeForm(index)">
              Remove Delay
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col">
          <div class="alert alert-danger" role="alert" v-if="submissionError">
            Submitting the report failed.
          </div>
          <div class="alert alert-success" role="alert" v-if="submissionSuccess">
            Delay Report(s): <div v-for="link in response" :key="link.data.url"><a target="_blank"
                rel="noopener noreferrer" :href="link.data.url">{{ link.data.url }}</a></div>
          </div>
          <div class="alert alert-danger" role="alert" v-if="saveError">
            Saving the report failed.
          </div>
          <div class="alert" role="alert" v-if="saveSuccess">
            Saved delay report progress at {{ lastSave.toLocaleString() }}.
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col col-6 approval-bar">
          <button data-testid="add-delay" type="button" class="btn btn-info" v-on:click="addForm">Add Delay</button>
          <span class="d-inline-block" data-toggle="tooltip" :title="submitDisabledReason">
            <button data-testid="submit-truck-delay-report" type="button" class="btn btn-success" v-on:click="submitTruckDelayForms"
              :disabled="isSubmitDisabled">
              <span>Submit Delays</span>
              <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </button>
          </span>
          <button data-testid="save-truck-delay-reports" type="button" class="btn btn-warning" v-on:click="save" :disabled="isSaveDisabled">
            Save Progress
          </button>
        </div>
      </div>
    </div>
    <div v-if="TDLTickets?.length > 0" class="col">
      <h5>TDL Tickets:</h5>
      <ul id="tdl-tickets" class="list-group">
        <li class="list-group-item border border-0" v-for="tdl in displayedTDLTickets" :key="tdl.url"><a target="_blank"
            rel="noopener noreferrer" :href="tdl">{{ tdl }}</a></li>
      </ul>
      <div class="tdl-pages">
        <button class="btn btn-secondary" :disabled="currentTDLTicketPage === 1"
          @click="currentTDLTicketPage--">Prev</button>
        <span>{{ currentTDLTicketPage }} / {{ totalTDLTicketPages }}</span>
        <button class="btn btn-secondary" :disabled="currentTDLTicketPage === totalTDLTicketPages"
          @click="currentTDLTicketPage++">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import utilities from '@/lib/utilities';

const ymd = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export default {
  data() {
    return {
      initialLoad: true,
      lastSave: undefined,
      isSafetyConductor: false,
      submittingReport: false,
      submissionError: false,
      submissionSuccess: false,
      saveError: false,
      saveSuccess: false,
      truckDelayReports: [],
      response: '',
      TDLTickets: [],
      TDLTicketPageSize: 5,
      currentTDLTicketPage: 1,
      defaultDelayReport: {
        delay_start_date: ymd(new Date()),
        delay_duration_minutes: 5,
        delay_category_primary: 'ADS',
        delay_category_secondary: '',
        description: ''
      }
    };
  },
  props: ['test'],
  async mounted() {
    this.debouncedSave = utilities.debounce(() => {
      this.saveForm();
    });
    const claims = await this.$auth.getUser();
    this.isSafetyConductor = claims.groups.includes('test_status_safety_conductors');
    this.defaultDelayReport.vehicle_id = this.test?.vehicle_id;
    this.defaultDelayReport.location = ['ABQ', 'AUS', 'BCB', 'MAD', 'All', 'Other'].includes(this.test?.location?.slice(0, 3)) ? this.test?.location?.slice(0, 3) : '';
    this.TDLTickets = this.test?.truck_delay_tickets;
    this.getSavedTruckDelayForms();
  },
  computed: {
    isSubmitDisabled() {
      return !this.isSafetyConductor || !this.areFormsValid || !this.hasDelayReports || this.submittingReport;
    },
    isSaveDisabled() {
      return !this.isSafetyConductor || !this.hasDelayReports;
    },
    areFormsValid() {
      return this.truckDelayReports.every((truckDelayReport) => (
        truckDelayReport.vehicle_id
        && truckDelayReport.location
        && truckDelayReport.delay_start_date
        && truckDelayReport.delay_duration_minutes
        && truckDelayReport.delay_category_primary
        && truckDelayReport.delay_category_secondary
        && truckDelayReport.description));
    },
    totalTDLTicketPages() {
      return Math.ceil(this.TDLTickets?.length / this.TDLTicketPageSize);
    },
    displayedTDLTickets() {
      const start = (this.currentTDLTicketPage - 1) * this.TDLTicketPageSize;
      const end = start + this.TDLTicketPageSize;
      return this.TDLTickets?.slice(start, end);
    },
    submitDisabledReason() {
      if (!this.isSafetyConductor) {
        return 'Only safety conductors can submit delays';
      }

      if (!this.areFormsValid) {
        return 'Some items have invalid entries, please ensure all required fields are filled out completely and correctly.';
      }

      if (!this.hasDelayReports) {
        return 'Add some delays to submit them.';
      }

      return '';
    },
    hasDelayReports() {
      return this.truckDelayReports?.length > 0;
    }
  },
  methods: {
    async getSavedTruckDelayForms() {
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      try {
        const savedTruckDelayForms = await client.get(`/v1/vehicle_tests/${this.$route.params.testId}/truck_delays`);
        if (savedTruckDelayForms.data.length > 0) {
          this.truckDelayReports = savedTruckDelayForms.data.sort((a, b) => a.created_at - b.created_at);
        }
      } catch (e) {
        datadogLogs.logger.error('Error getting saved TDL forms', {
          testId: this.$route.params.testId,
          error: e
        });
      } finally {
        this.initialLoad = false;
        if (!this.hasDelayReports) {
          this.truckDelayReports.push({ ...this.defaultDelayReport });
        }
      }
    },
    async submitTruckDelayForms() {
      this.submissionError = false;
      this.submissionSuccess = false;
      this.submittingReport = true;
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      try {
        const promises = this.truckDelayReports.map((report) => {
          console.log('Submitting truck delay reports...');
          return client.put(`/v1/vehicle_tests/${this.$route.params.testId}/truck_delays/${report.id}`, { status: 'submitted' });
        });
        const responses = await Promise.all(promises);
        this.response = responses;
        for (let r = 0; r < responses.length; r += 1) {
          this.TDLTickets.push(responses[r].data.url);
        }
        this.submissionSuccess = true;
        this.submissionError = false;
      } catch (e) {
        this.submissionError = true;
        this.submissionSuccess = false;
        datadogLogs.logger.error('Error submitting TDL report', {
          testId: this.$route.params.testId,
          error: e
        });
      } finally {
        this.submittingReport = false;
      }
    },
    addForm() {
      this.truckDelayReports.push({ ...this.defaultDelayReport });
    },
    async removeForm(index) {
      if ('id' in this.truckDelayReports[index]) {
        const { accessToken } = await this.$auth.tokenManager.get('accessToken');
        const client = axios.create({
          baseURL: window.location.origin,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        try {
          await client.delete(`/v1/vehicle_tests/${this.$route.params.testId}/truck_delays/${this.truckDelayReports[index].id}`);
        } catch (e) {
          datadogLogs.logger.error('Error removing TDL report', {
            testId: this.$route.params.testId,
            error: e
          });
        }
      }
      this.truckDelayReports.splice(index, 1);
    },
    async save() {
      this.debouncedSave();
    },
    async saveForm() {
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      try {
        const promises = this.truckDelayReports.map((report) => {
          const payload = { ...report };
          if (payload.delay_category_secondary === 'None') {
            payload.delay_category_secondary = '';
          }
          if (!payload.delay_duration_minutes) {
            payload.delay_duration_minutes = 0;
          }
          if ('id' in payload) {
            delete payload.created_at;
            return client.put(`/v1/vehicle_tests/${this.$route.params.testId}/truck_delays/${payload.id}`, payload);
          }
          return client.post(`/v1/vehicle_tests/${this.$route.params.testId}/truck_delay`, payload);
        });
        const responses = await Promise.all(promises);
        for (let r = 0; r < responses.length; r += 1) {
          this.truckDelayReports[r].id = responses[r].data.id;
        }
        this.saveSuccess = true;
        this.saveError = false;
        this.lastSave = new Date();
      } catch (e) {
        this.saveError = true;
        datadogLogs.logger.error('Error saving TDL report', {
          testId: this.$route.params.testId,
          error: e
        });
      } finally {
        this.submittingReport = false;
      }
    }
  }
};
</script>
