<template>
  <div class="loading-overlay" v-if="dataLoading">
    <div class="uil-ring-css" style="transform:scale(0.79);">
      <div></div>
    </div>
  </div>
  <div id="pre-test-report-form" class="row" @input="autoSave">
    <div class="col">
      <div class="row">
        <div class="col col-md-2">Approval Status:</div>
        <div id="approval-status" class="col col-md-4">{{ approvalStatus() }}</div>
      </div>
      <ReportFormGenerator :formTemplate="formTemplate" :form="preCheckReport" :autoSave="debouncedSave"
        :readOnlyMode="reportApproved" />
      <div class="row" v-if="!reportApproved">
        <div class="col form-check">
          <input class="form-check-input" type="checkbox" id="submit_for_approval"
            v-model="preCheckReport.data.submitForApproval">
          <label class="form-check-label" for="submit_for_approval">
            Notify that this ticket is ready for supervisor approval
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b>
            Once complete, submit checklist to Site Ops and request clearance before departing.
            Complete Final Ready-To-Drive checklist every time the truck moves from a parked position.
          </b>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-danger" role="alert" v-if="submissionError">
            Saving the report failed. Your session may have expired, please log out and back in again.
          </div>
          <div class="alert alert-success" role="alert" v-if="submissionSuccess">
            Report Saved!
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <button id="save-pre-check-info" class="btn btn-primary" v-if="!reportApproved" v-on:click="saveReport"
            :disabled="!isSafetyConductor || submittingReport">
            <span v-if="!submittingReport">Save</span>
            <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
          </button>
        </div>
      </div>
      <div class="row" v-if="isSupervisor">
        <div class="col col-4 approval-bar">
          <button id="approve-pre-trip-report" type="button" class="btn btn-success" v-if="!reportApproved"
            v-on:click="submitApproval" :disabled="submittingReport">
            <span v-if="!submittingReport">Approve</span>
            <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
          </button>
          <button id="reject-pre-trip-report" type="button" class="btn btn-danger" v-on:click="submitRejection"
            :disabled="submittingReport">
            <span v-if="!submittingReport">Reject</span>
            <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import ReportFormGenerator from '@/components/ReportFormGenerator.vue';
import forms from '@/lib/forms';
import utilities from '@/lib/utilities';

const resolveLocation = (preCheckReport) => (preCheckReport.data.location || 'unknown').toLowerCase();

const approvalChecks = [
  (preCheckReport, group) => (
    resolveLocation(preCheckReport).includes('abq')
    && ['abq_site_ops_managers', 'cloud_team_dev'].includes(group)),
  (preCheckReport, group) => (
    resolveLocation(preCheckReport).includes('bcb')
    && ['bcb_site_ops_managers', 'cloud_team_dev'].includes(group)),
  (preCheckReport, group) => (
    resolveLocation(preCheckReport).includes('mad')
    && ['mad_site_ops_managers', 'cloud_team_dev'].includes(group)),
  (preCheckReport, group) => (
    !_.some(['abq', 'bcb', 'mad'], (l) => resolveLocation(preCheckReport).includes(l))
    && ['abq_site_ops_managers', 'bcb_site_ops_managers', 'mad_site_ops_managers', 'cloud_team_dev'].includes(group))
];

export default {
  components: {
    ReportFormGenerator
  },
  data() {
    return {
      isSafetyConductor: false,
      isSupervisor: false,
      submittingReport: false,
      submissionError: false,
      submissionSuccess: false,
      dataLoading: true,
      formTemplate: null,
      preCheckReport: {
        form_version: forms.preTripForms.default.version,
        vehicle_id: this.test.vehicle_id,
        data: {}
      },
      debouncedSave: null,
      initialLoad: true,
      reportApproved: false
    };
  },
  props: ['test'],
  async mounted() {
    this.debouncedSave = utilities.debounce(() => {
      if (!this.reportApproved) {
        if (this.initialLoad) {
          this.initialLoad = false;
        } else {
          this.saveReport();
        }
      }
    });
    const claims = await this.$auth.getUser();
    this.isSafetyConductor = claims.groups.includes('test_status_safety_conductors');
    const { accessToken } = await this.$auth.tokenManager.get('accessToken');
    const client = axios.create({
      baseURL: window.location.origin,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    try {
      const treqs = 'treqs' in this.$route.query;
      const report = await client.get(
        `/v1/vehicle_tests/${this.$route.params.testId}/prechecks`,
        { params: treqs ? { treqs: true } : {} }
      );
      this.preCheckReport = report.data;

      this.isSupervisor = claims.groups.reduce(
        (isSupervisor, group) => isSupervisor || _.some(approvalChecks, (check) => check(this.preCheckReport, group)),
        false
      );

      const { signoff } = this.preCheckReport;
      this.reportApproved = signoff && (signoff.approval_status === 'approved');
    } catch (e) {
      this.preCheckReport.id = this.$route.params.testId;
      this.preCheckReport.vehicle_id = this.test.vehicle_id;
      this.preCheckReport.data.location = this.test.location;
      this.preCheckReport.data.trailerId = this.test.trailer_id;
      this.preCheckReport.data.softwareBranch = this.test.sw_branch;
      this.preCheckReport.data.softwareCommitHash = this.test.sw_commit_hash;
    } finally {
      this.formTemplate = forms.preTripForms[this.preCheckReport.form_version] || forms.preTripForms.default;
      this.dataLoading = false;
    }
  },
  methods: {
    async submitApproval() {
      this.submittingReport = true;
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const signoff = {
        approval_status: 'approved',
        reason: ''
      };
      this.preCheckReport.signoff = signoff;
      try {
        const treqs = 'treqs' in this.$route.query;
        await client.put(
          `/v1/vehicle_tests/${this.test.id}/prechecks`,
          this.preCheckReport,
          { params: treqs ? { treqs: true } : {} }
        );
        this.reportApproved = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.submittingReport = false;
      }
    },
    async submitRejection() {
      this.submittingReport = true;
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const signoff = {
        approval_status: 'not_ready',
        reason: ''
      };
      this.preCheckReport.signoff = signoff;
      this.preCheckReport.data.submitForApproval = false;
      try {
        const treqs = 'treqs' in this.$route.query;
        await client.put(
          `/v1/vehicle_tests/${this.test.id}/prechecks`,
          this.preCheckReport,
          { params: treqs ? { treqs: true } : {} }
        );
        this.reportApproved = false;
      } catch (e) {
        console.log(e);
      } finally {
        this.submittingReport = false;
      }
    },
    async saveReport() {
      this.submissionError = false;
      this.submissionSuccess = false;
      this.submittingReport = true;
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      try {
        const signoff = {};

        if (!this.preCheckReport.data.submitForApproval) {
          signoff.approval_status = 'not_ready';
          signoff.reason = '';
        } else if (this.preCheckReport.signoff && this.preCheckReport.signoff.approval_status === 'approved') {
          signoff.approval_status = this.preCheckReport.signoff.approval_status;
          signoff.reason = this.preCheckReport.signoff.reason;
        } else {
          signoff.approval_status = 'pending';
          signoff.reason = '';
        }

        this.preCheckReport.signoff = signoff;

        const treqs = 'treqs' in this.$route.query;
        await client.put(
          `/v1/vehicle_tests/${this.test.id}/prechecks`,
          this.preCheckReport,
          { params: treqs ? { treqs: true } : {} }
        );
        this.submissionSuccess = true;
      } catch (e) {
        this.submissionError = true;
        datadogLogs.logger.error('Error saving pre-test report', {
          testId: this.$route.params.testId,
          error: e
        });
      } finally {
        this.submittingReport = false;
      }
    },
    approvalStatus() {
      const statuses = {
        not_ready: 'Unfinished',
        pending: 'Ready for Approval',
        approved: 'Approved'
      };
      if (this.preCheckReport.signoff) {
        return statuses[this.preCheckReport.signoff.approval_status];
      }
      return 'Empty';
    },
    async autoSave() {
      this.debouncedSave();
    }
  }
};
</script>
