<template>
  <div class="loading-overlay" v-if="dataLoading">
    <div class="uil-ring-css" style="transform:scale(0.79);">
      <div></div>
    </div>
  </div>
  <div id="post-check-report-form" class="row" @input="autoSave">
    <div class="col">
      <ReportFormGenerator
        :formTemplate="formTemplate"
        :form="form"
        :autoSave="debouncedSave"
        :readOnlyMode="reportComplete || !isReporterLoggedInUser"
        :missingFields="missingFields"
        @formRequiredField="setRequiredFields"/>
      <div class="row">
        <div class="col">
          <div class="alert alert-warning" role="alert" v-if="validationError">
            Report Validation Failed. Please ensure all required fields have data.
          </div>
          <div class="alert alert-danger" role="alert" v-if="submissionError">
            Saving the report failed. Your session may have expired, please log out and back in again.
          </div>
          <div class="alert alert-success" role="alert" v-if="submissionSuccess">
            Report Saved!
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-4 approval-bar">
          <button
            id="save-post-check-info"
            class="btn btn-primary"
            v-on:click="submitForm"
            v-if="!reportComplete && isReporterLoggedInUser"
            :disabled="!isSafetyConductor || submittingReport">
            <span v-if="!submittingReport">Save</span>
            <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <button
            id="complete-post-check-info"
            class="btn btn-success"
            v-on:click="completeReport"
            v-if="!reportComplete && isReporterLoggedInUser"
            :disabled="!isSafetyConductor || submittingReport">
              <span v-if="!submittingReport">Complete Report</span>
              <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <button
            id="reopen-post-check-info"
            class="btn btn-danger"
            v-on:click="reopenReport"
            v-if="reportComplete && isReporterLoggedInUser"
            :disabled="!isSafetyConductor || submittingReport">
              <span v-if="!submittingReport">Reopen Report</span>
              <span v-if="submittingReport" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import ReportFormGenerator from '@/components/ReportFormGenerator.vue';
import forms from '@/lib/forms';
import utilities from '@/lib/utilities';

export default {
  components: {
    ReportFormGenerator
  },
  data() {
    return {
      isSafetyConductor: false,
      submittingReport: false,
      validationError: false,
      submissionError: false,
      submissionSuccess: false,
      dataLoading: true,
      formTemplate: null,
      form: {
        version: forms.postTripForms.default.version,
        vehicle_id: this.test.vehicle_id,
        data: {}
      },
      debouncedSave: null,
      initialLoad: true,
      reportComplete: false,
      isReporterLoggedInUser: false,
      requiredFields: [],
      missingFields: []
    };
  },
  props: ['test'],
  async mounted() {
    this.debouncedSave = utilities.debounce(() => {
      if (!this.reportComplete) {
        if (this.initialLoad) {
          this.initialLoad = false;
        } else {
          this.submitForm();
        }
      }
    });
    const { accessToken } = await this.$auth.tokenManager.get('accessToken');
    this.claims = await this.$auth.getUser();
    this.isSafetyConductor = this.claims.groups.includes('test_status_safety_conductors');
    const client = axios.create({
      baseURL: window.location.origin,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    try {
      const treqs = 'treqs' in this.$route.query;
      const report = await client.get(
        `/v1/vehicle_tests/${this.$route.params.testId}/posttestreport`,
        { params: treqs ? { treqs: true } : {} }
      );
      this.form = report.data;

      const { signoff } = this.form;
      if (signoff) {
        /* eslint-disable */
        const { completion_status, reporter } = signoff;
        this.reportComplete = completion_status === 'complete';
        this.isReporterLoggedInUser = reporter === this.claims.email;
      } else {
        this.isReporterLoggedInUser = true;
      }
    } catch (e) {
      this.form.id = this.$route.params.testId;
      this.isReporterLoggedInUser = true;
      try {
        const treqs = 'treqs' in this.$route.query;
        const prereport = await client.get(
          `/v1/vehicle_tests/${this.$route.params.testId}/prechecks`,
          { params: treqs ? { treqs: true } : {} }
        );
        this.form.vehicle_id = prereport.data.vehicle_id;
        this.form.data.date = prereport.data.data.date;
        this.form.data.location = prereport.data.data.location;
        this.form.data.safetyConductor = prereport.data.data.safetyConductor;
        this.form.data.safetyDriver = prereport.data.data.safetyDriver;
        this.form.data.trailerId = prereport.data.data.trailerId;
        this.form.data.softwareBranch = prereport.data.data.softwareBranch;
        this.form.data.softwareCommitHash = prereport.data.data.softwareCommitHash;
        this.form.data.safetyConductor = prereport.data.data.safetyConductor;
        this.form.data.safetyDriver = prereport.data.data.safetyDriver;
        this.form.data.ses = prereport.data.data.ses;
        this.form.data.passengers = prereport.data.data.passengers;
      } catch {
        console.log('No precheck to pull from yet');
      }
    } finally {
      this.formTemplate = forms.postTripForms[this.form.version] || forms.postTripForms.default ;
      this.dataLoading = false;
    }
  },
  methods: {
    setRequiredFields(formRequiredField) {
      if (!this.requiredFields.includes(formRequiredField)) {
        this.requiredFields.push(formRequiredField);
      }
    },
    async checkRequiredFields(fields) {
      this.missingFields = [];
      this.validationError = false;
      this.submissionSuccess = false;
      fields.forEach((field) => {
        if (this.form.data[field] == null || this.form.data[field] === '') {
          this.missingFields.push(field);
          this.validationError = true;
        }
      });
    },
    async saveForm() {
      this.submissionError = false;
      this.submissionSuccess = false;
      this.submittingReport = true;
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      try {
        const treqs = 'treqs' in this.$route.query;
        const response = await client.put(
          `/v1/vehicle_tests/${this.$route.params.testId}/posttestreport`,
          this.form,
          { params: treqs ? { treqs: true } : {} }
        );
        this.submissionSuccess = true;
        return response;
      } catch (e) {
        this.submissionError = true;
        datadogLogs.logger.error('Error saving post-test report', {
          testId: this.$route.params.testId,
          error: e
        });
      } finally {
        this.submittingReport = false;
      }
    },
    async updateCompletionStatus(completionStatus) {
      if (!this.form.signoff) {
        this.form.signoff = { history: [] };
      }
      if (!this.form.signoff.history) {
        this.form.signoff.history = [];
      }
      const completionDate = new Date().toString();
      this.form.signoff = {
        completion_status: completionStatus,
        reporter: this.claims.email,
        date: completionDate,
        history: [
          ...this.form.signoff.history,
          {
            completion_status: completionStatus,
            reporter: this.claims.email,
            date: completionDate
          }
        ]
      };
      try {
        await this.saveForm();
        this.reportComplete = completionStatus === 'complete';
      } catch (e) {
        console.log(e);
      }
    },
    async completeReport() {
      await this.checkRequiredFields(this.requiredFields);
      if (!this.validationError) {
        await this.updateCompletionStatus('complete');
      }
    },
    async reopenReport() {
      await this.updateCompletionStatus('reopened');
    },
    async submitForm() {
      await this.saveForm();
    },
    async autoSave() {
      this.debouncedSave();
    }
  }
};
</script>
