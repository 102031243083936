<template>
  <div id="tests" class="row">
    <div class="col-md-2">
      <span>Select Test</span>
    </div>
    <div class="col">
      <vSelect
        class="form-control"
        id="test-choices"
        :options="options"
        :value="selectedId"
        @option:selected="updateSelectedTest"
        v-if="!loadingTests">
      </vSelect>
      <span v-if="loadingTests" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select/src/index';

export default {
  components: {
    vSelect
  },
  data() {
    return {
      loadingTests: true,
      options: [],
      selectedId: '',
      tests: []
    };
  },
  async mounted() {
    const { accessToken } = await this.$auth.tokenManager.get('accessToken');
    const client = axios.create({
      baseURL: window.location.origin,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    const treqs = 'treqs' in this.$route.query;
    const scheduledParams = treqs === true ? { status: 'scheduled', treqs: true } : { status: 'scheduled' };
    const inProgressParams = treqs === true ? { status: 'in_progress', treqs: true } : { status: 'in_progress' };
    const afterActionParams = treqs === true ? { status: 'after_action', treqs: true } : { status: 'after_action' };
    const verifyParams = treqs === true ? { status: 'verify', treqs: true } : { status: 'verify' };

    const { data: scheduledTests } = await client.get('/v1/vehicle_tests', { params: scheduledParams });
    const { data: inProgressTests } = await client.get('/v1/vehicle_tests', { params: inProgressParams });
    const { data: afterActionTests } = await client.get('/v1/vehicle_tests', { params: afterActionParams });
    const { data: verifyTests } = await client.get('/v1/vehicle_tests', { params: verifyParams });
    const vehicleTests = [
      ...scheduledTests,
      ...inProgressTests,
      ...afterActionTests,
      ...verifyTests
    ];
    this.tests = vehicleTests;
    this.options = vehicleTests.map((test) => (
      {
        label: `${test.id} ${test.name}`,
        code: `${test.id}`
      }
    ));
    this.loadingTests = false;
  },
  methods: {
    updateSelectedTest(valueEvent) {
      this.$router.push({
        path: `/tests/${valueEvent.code}`,
        query: 'treqs' in this.$route.query ? { treqs: null } : {}
      });
    }
  }
};
</script>
