export default {
  version: 'rev 2021-08 v5.0',
  sections: [
    {
      id: 'pre-trip-report-header-section',
      headerSection: true,
      fields: [
        {
          id: 'pre-trip-report-version',
          model: 'form_version',
          label: 'Report Version',
          type: 'readonly',
          isData: false
        },
        {
          id: 'location',
          model: 'location',
          label: 'Location',
          type: 'text',
          isData: true
        },
        {
          id: 'test-id',
          model: 'id',
          label: 'Test ID',
          type: 'readonly',
          isData: false
        },
        {
          id: 'safety-conductor',
          model: 'safetyConductor',
          label: 'SC',
          type: 'text',
          isData: true
        },
        {
          id: 'odometer',
          model: 'odometer',
          label: 'Odometer',
          type: 'text',
          isData: true
        },
        {
          id: 'safety-driver',
          model: 'safetyDriver',
          label: 'SD',
          type: 'text',
          isData: true
        },
        {
          id: 'date',
          model: 'date',
          label: 'Date',
          type: 'text',
          isData: true
        },
        {
          id: 'ses',
          model: 'ses',
          label: 'SE(s)',
          type: 'text',
          isData: true
        },
        {
          id: 'time-checklist-started',
          model: 'timeStarted',
          label: 'Time Checklist Started',
          type: 'text',
          isData: true
        },
        {
          id: 'passengers',
          model: 'passengers',
          label: 'Passengers',
          type: 'text',
          isData: true
        },
        {
          id: 'time-of-departure',
          model: 'departureTime',
          label: 'Time of Departure',
          type: 'text',
          isData: true
        },
        {
          id: 'vehicle-id',
          model: 'vehicle_id',
          label: 'Vehicle ID',
          type: 'text',
          isData: false
        },
        {
          id: 'pre-check-sign-off',
          model: 'scSignOff',
          label: 'SC Sign-off',
          type: 'text',
          isData: true
        }
      ]
    },
    {
      id: 'pre-trip-section-header',
      headerSection: false,
      header: 'Pre-Trip',
      subsections: [
        {
          id: 'pre-trip-pre-vehicle-entry-checks-section',
          header: '1. Pre-Vehicle Entry Checks',
          fields: [
            {
              id: 'verify-ticket',
              model: 'verifyTicket',
              label: 'Verify test ticket filed, approved, and scheduled',
              type: 'boolean',
              isData: true
            },
            {
              id: 'review-tpp',
              model: 'reviewTpp',
              label: 'Review the TPP quick ref from test ticket',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-vehicle-inspection',
              model: 'verifyVehicleInspection',
              label: 'Verify SD completed vehicle inspection',
              type: 'boolean',
              isData: true
            }
          ]
        },
        {
          id: 'pre-trip-compute-startup-and-software-deployment-section',
          header: '2. Compute Startup and Software Deployment',
          fields: [
            {
              id: 'verify-no-prior-work',
              model: 'verifyNoPriorWork',
              label: 'Verify no prior work in progress (tagout placards)',
              type: 'boolean',
              isData: true
            },
            {
              id: 'ensure-ac',
              model: 'ensureAC',
              label: 'Ensure AC in back is turned on high before starting the truck',
              type: 'boolean',
              isData: true
            },
            {
              id: 'reprovision-computes',
              model: 'reprovisionComputes',
              label: 'If it is the first test of the day, reprovision all computes from the Platform UI',
              type: 'boolean',
              isData: true
            },
            {
              id: 'deploy-software',
              model: 'deploySoftware',
              label: 'Deploy the software in the test ticket using the Platform UI',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-correct-software',
              model: 'verifyCorrectSoftware',
              label: 'Verify Test Tool displays the correct software per the test ticket',
              type: 'boolean',
              isData: true
            }
          ]
        },
        {
          id: 'pre-trip-safety-and-info-discussion-section',
          header: '3. Pre-Trip Safety and Info Discussion',
          fields: [
            {
              id: 'review-tpp-verified',
              model: 'reviewTPPVerified',
              label: 'All crew have reviewed and understand test details and TPP quick ref',
              type: 'boolean',
              isData: true
            },
            {
              id: 'understand-vehicle-specific-considerations',
              model: 'understandVehicleSpecificConsiderations',
              label: 'All crew understand vehicle specific considerations',
              type: 'boolean',
              isData: true
            },
            {
              id: 'acknowledge-considerations',
              model: 'acknowledgeConsiderations',
              label: 'All crew acknowledge the considerations, protocols, and limitations of the current ADS software version',
              type: 'boolean',
              isData: true
            },
            {
              id: 'weather-awareness',
              model: 'weatherAwareness',
              label: 'All crew are aware of anticipated weather conditions along route and weather-related ODD restrictions',
              type: 'boolean',
              isData: true
            },
            {
              id: 'understand-roles',
              model: 'understandRoles',
              label: 'All crew understands his/her role(s) and responsibilities',
              type: 'boolean',
              isData: true
            },
            {
              id: 'understand-route-parameters',
              model: 'understandRouteParameters',
              label: 'All crew understand the latest ODD route parameters',
              type: 'boolean',
              isData: true
            },
            {
              id: 'understand-emergency-roles',
              model: 'understandEmergencyRoles',
              label: 'All crew understands his/her role(s) and responsibilities during an emergency incident',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-fuel-level',
              model: 'verifyFuelLevel',
              label: 'Verify fuel level (ideally above 3/4 tank)',
              type: 'boolean',
              isData: true
            },
            {
              id: 'confirm-no-safety-dash-lights',
              model: 'confirmNoSafetyDashLights',
              label: 'Confirm no safety-critical dash indicator lights are on',
              type: 'boolean',
              isData: true
            }
          ]
        }
      ]
    },
    {
      id: 'pre-departure-section',
      header: 'Pre-Departure',
      headerSection: false,
      subsections: [
        {
          id: 'pre-departure-staging-section',
          header: '4. Staging',
          fields: [
            {
              id: 'confirm-radio',
              model: 'confirmRadio',
              label: 'Confirm radio communication with Site Ops',
              type: 'boolean',
              isData: true
            },
            {
              id: 'confirm-cell-phone',
              model: 'confirmCellPhone',
              label: 'Confirm cell phone battery above 80% and charging',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-vehicle-clear-to-move',
              model: 'verifyVehicleClearToMove',
              label: 'SC and SD verify the vehicle is clear to move (no cables attached or objects in the way)',
              type: 'boolean',
              isData: true
            },
            {
              id: 'confirm-ocu',
              model: 'confirmOcu',
              /* eslint-disable */
              label: 'Ensure OCU is powered on and wireless keyboard is connected and working (navigate to <a href="http://ocu" target="_blank">http://ocu</a>)',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-ocu-audio',
              model: 'verifyOcuAudio',
              label: 'Verify OCU audio prompts if enacted',
              type: 'boolean',
              isData: true
            },
            {
              id: 'start-test-on-test-tool',
              model: 'startTestOnTestTool',
              /* eslint-disable */
              label: 'Stage, join, and start a test on the test tool with appropriate crew info: (<a href="http://vtt.vehicle.torc.tech" target="_blank">http://vtt.vehicle.torc.tech</a>)',
              type: 'boolean',
              isData: true
            },
            {
              id: 'input-staging-route',
              model: 'inputStagingRoute',
              label: 'Input staging route into OCU',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-health-diagnostics',
              model: 'verifyHealthDiagnostics',
              label: 'Verify all vehicle and ADS health monitoring diagnostics are reviewed, understood, and addressed',
              type: 'boolean',
              isData: true
            },
            {
              id: 'site-ops-approval-for-staging-area',
              model: 'siteOpsApprovalForStagingArea',
              label: 'Contact Site Ops for approval to move to staging area; upon approval depart to staging',
              type: 'boolean',
              isData: true
            }
          ]
        },
        {
          id: 'pre-departure-robotic-entry-and-disengagement-checks-section',
          header: '5. Robotic Entry and Disengagement Checks',
          fields: [
            {
              id: 'ready-state-achieved',
              model: 'readyStateAchieved',
              label: 'Ready state achieved',
              type: 'boolean',
              isData: true
            },
            {
              id: 'enter-robotic',
              model: 'enterRobotic',
              label: 'Enter robotic',
              type: 'boolean',
              isData: true
            },
            {
              id: 'steering',
              model: 'steering',
              label: 'Steering',
              type: 'boolean',
              isData: true
            },
            {
              id: 'throttle',
              model: 'throttle',
              label: 'Throttle',
              type: 'boolean',
              isData: true
            },
            {
              id: 'cancel-button',
              model: 'cancelButton',
              label: 'Cancel button',
              type: 'boolean',
              isData: true
            },
            {
              id: 'brake',
              model: 'brake',
              label: 'Brake',
              type: 'boolean',
              isData: true
            },
            {
              id: 'engine-brake',
              model: 'engineBrake',
              label: 'Engine brake',
              type: 'boolean',
              isData: true
            }
          ]
        },
        {
          id: 'pre-departure-final-checks-section',
          header: '6. Final Checks Prior to Departure',
          headerSection: false,
          fields: [
            {
              id: 'troubleshoot-errors-in-ocu',
              model: 'troubleshootErrorsInOcu',
              label: 'Check and troubleshoot any errors and warnings present in OCU and Blame Graph',
              type: 'boolean',
              isData: true
            },
            {
              id: 'verify-sensor-and-camera-output',
              model: 'verifySensorAndCameraOutput',
              label: 'SC or SE uses visualization tools to verify sensor and camera output',
              type: 'boolean',
              isData: true
            },
            {
              id: 'input-testing-route-to-ocu',
              model: 'inputTestingRouteToOcu',
              label: 'Input testing route into OCU',
              type: 'boolean',
              isData: true
            }
          ]
        }
      ]
    },
    {
      id: 'remarks-section',
      headerSection: false,
      footerSection: true,
      fields: [
        {
          id: 'remarks',
          model: 'remarks',
          label: 'Remarks (include delay summary)',
          type: 'richtext',
          isData: true
        }
      ]
    }
  ]
};
