import OktaSignIn from '@okta/okta-signin-widget';
import { OktaAuth } from '@okta/okta-auth-js';
import torcLogo from '../img/logo.svg';

const oktaSignIn = new OktaSignIn({
  baseUrl: 'https://torcrobotics.okta.com',
  clientId: '0oa4chve55PMzFiEz4x7',
  redirectUri: `${window.location.origin}/login/callback`,
  useClassicEngine: true,
  logo: torcLogo,
  logoText: 'Torc Robotics',
  authParams: {
    pkce: true,
    issuer: 'https://torcrobotics.okta.com/oauth2/auszdydcnG2pizCdb4x6',
    display: 'page',
    scopes: ['openid', 'profile', 'email', 'groups', 'offline_access']
  }
});

const oktaAuth = new OktaAuth({
  issuer: 'https://torcrobotics.okta.com/oauth2/auszdydcnG2pizCdb4x6',
  clientId: '0oa4chve55PMzFiEz4x7',
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email', 'groups', 'offline_access']
});

export { oktaAuth, oktaSignIn };
