import axios from 'axios';

class UploadAdapter {
  constructor(loader, options) {
    this.loader = loader;
    this.options = options;
  }

  upload() {
    return this.loader.file
      .then((file) => new Promise((resolve, reject) => {
        this.sendRequest(file, resolve, reject);
      }));
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  sendRequest(file, resolve, reject) {
    const reader = new window.FileReader();

    reader.addEventListener('load', () => {
      const data = {
        file: reader.result,
        name: file.name,
        size: file.size
      };
      const accessToken = this.options.auth.getAccessToken();
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      client.post(`/v1/vehicle_tests/${this.options.testId}/screenshots`, data).then((response) => {
        resolve({
          default: response.data.url
        });
      }).catch((error) => reject(error));
    });
    reader.readAsDataURL(file);
  }
}

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => { // eslint-disable-line
    return new UploadAdapter(loader, editor.config.get('uploadAdapter'));
  };
}
