<template>
  <div class="login">
    <div id="okta-signin-container"></div>
  </div>
</template>

<script>
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { oktaSignIn } from '../okta';

export default {
  name: 'Login',
  mounted() {
    this.$nextTick(() => {
      const originalUri = this.$auth.getOriginalUri();
      if (!originalUri) {
        this.$auth.setOriginalUri('/');
      }

      oktaSignIn.showSignInToGetTokens(
        { el: '#okta-signin-container' }
      ).then((tokens) => {
        this.$auth.handleLoginRedirect(tokens);
      }).catch((err) => {
        throw err;
      });
    });
  },
  unmounted() {
    oktaSignIn.remove();
  }
};
</script>
