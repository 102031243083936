import rev202105v32 from './post_trip_forms/rev_2021-05_v3_2';
import rev202201v40PostTrip from './post_trip_forms/rev_2022-01_v4_0';
import rev202202v50PostTrip from './post_trip_forms/rev_2022-02_v5_0';
import rev202205v51PostTrip from './post_trip_forms/rev_2022-05_v5_1';
import rev202205v60PostTrip from './post_trip_forms/rev_2022-05_v6_0';
import rev202208v61PostTrip from './post_trip_forms/rev_2022-08_v6_1';
import rev202210v71PostTrip from './post_trip_forms/rev_2022-10_v7_1';
import rev202301v80PostTrip from './post_trip_forms/rev_2023-01_v8_0';
import rev202302v81PostTrip from './post_trip_forms/rev_2023-02_v8_1';
import rev202302v82PostTrip from './post_trip_forms/rev_2023-02_v8_2';
import rev202302v83PostTrip from './post_trip_forms/rev_2023-02_v8_3';
import rev202305v84PostTrip from './post_trip_forms/rev_2023-05_v8_4';
import rev202305v85PostTrip from './post_trip_forms/rev_2023-05_v8_5';
import rev202311v90PostTrip from './post_trip_forms/rev_2023-11_v9_0';

import rev202108v50 from './pre_trip_forms/rev_2021-08_v5_0';
import rev202201v60PreTrip from './pre_trip_forms/rev_2022-01_v6_0';
import rev202201v61PreTrip from './pre_trip_forms/rev_2022-01_v6_1';

export default {
  postTripForms: {
    'rev 2021-05 v3.2': rev202105v32,
    'rev 2022-01 v4.0': rev202201v40PostTrip,
    'rev 2022-02 v5.0': rev202202v50PostTrip,
    'rev 2022-05 v5.1': rev202205v51PostTrip,
    'rev 2022-05 v6.0': rev202205v60PostTrip,
    'rev 2022-08 v6.1': rev202208v61PostTrip,
    'rev 2022-10 v7.1': rev202210v71PostTrip,
    'rev 2023-01 v8.0': rev202301v80PostTrip,
    'rev 2023-02 v8.1': rev202302v81PostTrip,
    'rev 2023-02 v8.2': rev202302v82PostTrip,
    'rev 2023-02 v8.3': rev202302v83PostTrip,
    'rev 2023-05 v8.4': rev202305v84PostTrip,
    'rev 2023-05 v8.5': rev202305v85PostTrip,
    'rev 2023-11 v9.0': rev202311v90PostTrip,
    default: rev202311v90PostTrip
  },
  preTripForms: {
    'rev 2021-08 v5.0': rev202108v50,
    'rev 2022-01 v6.0': rev202201v60PreTrip,
    'rev 2022-01 v6.1': rev202201v61PreTrip,
    default: rev202201v61PreTrip
  }
};
