<template>
  <div id="test-info" class="row">
    <div class="col">
      <div class="row align-items-center">
        <div id="forbidden" class="col">
          <h1>Forbidden</h1>
        </div>
        <hr />
        <div class="row align-items-center">
          <div id="forbidden-description" class="col">
            <h4>You are forbidden from accessing test <b>{{ $route.params.testId }}</b> due to one or both of the following reasons:</h4>
          </div>
        </div>
        <div class="row align-items-center">
          <div id="forbidden-reasons" class="col">
            <ul>
              <li id="forbidden-reason-1">you do not have the permissions to access tests for this specific vehicle</li>
              <li id="forbidden-reason-2">you do not have the permissions to access tests that are marked as <b>restricted</b></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
