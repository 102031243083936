import './assets/scss/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-select/src/scss/vue-select.scss';
import 'bootstrap';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { createApp } from 'vue';
import OktaVue from '@okta/okta-vue';
import App from './App.vue';
import router from './router';
import { oktaAuth } from './okta';

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_DEPLOY_ENV) {
  console.info('initializing datadog');

  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_CLIENT_RUM_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_RUM_TOKEN,
    site: 'datadoghq.com',
    service: 'test-status-ui',
    env: process.env.VUE_APP_DEPLOY_ENV,
    version: process.env.VUE_APP_VCS_SHORT_REF,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    enableExperimentalFeatures: ['clickmap']
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_LOG_TOKEN,
    site: 'datadoghq.com',
    service: 'test-status-ui',
    version: process.env.VUE_APP_VCS_SHORT_REF,
    env: process.env.VUE_APP_DEPLOY_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['log', 'info', 'error'],
    sessionSampleRate: 100,
    useSecureSessionCookie: true
  });
}

datadogLogs.setGlobalContext({ team: 'dateng' });

oktaAuth.authStateManager.subscribe((authState) => {
  const authenticated = authState.isAuthenticated;
  if (authenticated) {
    datadogRum.setUser({
      email: authState.accessToken.claims.sub
    });
  } else {
    datadogRum.removeUser();
  }
});

createApp(App)
  .use(router)
  .use(OktaVue, {
    oktaAuth,
    onAuthRequired: () => {
      router.push('/login');
    },
    onAuthResume: () => {
      router.push('/login');
    }
  })
  .use(CKEditor)
  .mount('#app');
