<template>
  <div id="test-info" class="row">
    <div class="col">
      <div class="row align-items-center">
        <div class="col">
          <h1>{{ $route.params.testId }} - {{ test.name }}</h1>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col col-md-1">
          Status:
        </div>
        <div id="test-status" class="col col-md-2">{{ testStatus() }}</div>
        <button
          id="start-pre-check-button"
          class="col col-md-2 btn btn-primary"
          v-if="isTestScheduled"
          v-on:click="startPreCheck"
          :disabled="!isSafetyConductor || startingPreCheck">
          <span v-if="!startingPreCheck">Start Pre-Check</span>
          <span v-if="startingPreCheck" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
      </div>
      <div class="row">
        <div class="col">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pre-test-report-tab"
                data-bs-toggle="tab"
                data-bs-target="#pre-test-report"
                type="button"
                role="tab"
                aria-controls="pre-test-report"
                aria-selected="true">
                Pre-Test Report
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="post-test-report-tab"
                data-bs-toggle="tab"
                data-bs-target="#post-test-report"
                type="button"
                role="tab"
                aria-controls="post-test-report"
                aria-selected="false">
                Post-Test Report
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="truck-delay-report-tab"
                data-bs-toggle="tab"
                data-bs-target="#truck-delay-report"
                type="button"
                role="tab"
                aria-controls="truck-delay-report"
                aria-selected="false"
              >
                Delay
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                id="#create-work-order-button"
                class="nav-link"
                role="link"
                aria-selected="false"
                v-if="isTreqs"
              >
                <a target="_blank" v-bind:href="'https://apps.cloud.torc.tech/missions/' + $route.params.testId + '/work-order/create'">Create Work Order</a>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="row tab-content" id="myTabContent">
        <div class="col tab-pane fade show active" id="pre-test-report" role="tabpanel" aria-labelledby="pre-test-report-tab">
          <PreTestReport :test="test" v-if="test?.id" />
        </div>
        <div class="col tab-pane fade" id="post-test-report" role="tabpanel" aria-labelledby="post-test-report-tab">
          <PostTestReport :test="test" v-if="test?.id" />
        </div>
        <div class="col tab-pane fade" id="truck-delay-report" role="tabpanel" aria-labeledby="truck-delay-report-tab">
          <TruckDelayReport :test="test" v-if="test?.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PreTestReport from '@/components/PreTestReport.vue';
import PostTestReport from '@/components/PostTestReport.vue';
import TruckDelayReport from '@/components/TruckDelayReport.vue';

export default {
  components: {
    PreTestReport,
    PostTestReport,
    TruckDelayReport
  },
  data() {
    return {
      test: {},
      isTestScheduled: false,
      startingPreCheck: false,
      isSafetyConductor: false,
      isTreqs: false
    };
  },
  async mounted() {
    const { accessToken } = await this.$auth.tokenManager.get('accessToken');
    const client = axios.create({
      baseURL: window.location.origin,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    const treqs = 'treqs' in this.$route.query;
    const test = await client.get(
      `/v1/vehicle_tests/${this.$route.params.testId}`,
      { params: treqs ? { treqs: true } : {} }
    );
    if (test && test.status === 403) {
      this.$router.push({ name: 'forbidden', params: { testId: this.$route.params.testId } });
    }
    this.test = test.data;
    this.isTestScheduled = this.test.status === 'scheduled';
    const claims = await this.$auth.getUser();
    this.isSafetyConductor = claims.groups.includes('test_status_safety_conductors');
    this.isTreqs = this.test.id.includes('TREQS');
  },
  methods: {
    async startPreCheck() {
      this.startingPreCheck = true;
      const { accessToken } = await this.$auth.tokenManager.get('accessToken');
      const client = axios.create({
        baseURL: window.location.origin,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      const treqs = 'treqs' in this.$route.query;
      const response = await client.put(
        `/v1/vehicle_tests/${this.$route.params.testId}`,
        { status: 'in_progress' },
        { params: treqs ? { treqs: true } : {} }
      );
      if (response.status === 200) {
        this.isTestScheduled = false;
        this.test.status = 'in_progress';
      }
    },
    testStatus() {
      const statuses = {
        scheduled: 'Scheduled',
        in_progress: 'In Progress',
        after_action: 'After Action',
        verify: 'Verify',
        ready_for_test_ops_approval: 'Ready for Test/Ops Approval'
      };

      return statuses[this.test.status] || this.test.status;
    }
  }
};
</script>
