export default {
  version: 'rev 2023-11 v9.0',
  sections: [
    {
      id: 'post-trip-report-header-section',
      headerSection: true,
      fields: [
        {
          id: 'post-report-version',
          model: 'version',
          label: 'Report Version',
          type: 'readonly'
        },
        {
          id: 'ops-manager',
          model: 'opsManager',
          label: 'Ops Manager',
          type: 'text',
          isData: true
        },
        {
          id: 'odometer',
          model: 'odometer',
          label: 'Odometer',
          type: 'number',
          isData: true
        },
        {
          id: 'tire-tread-depth',
          model: 'tireTreadDepth',
          label: 'Tire Tread Depth',
          type: 'number',
          isData: true
        },
        {
          id: 'safety-conductor',
          model: 'safetyConductor',
          label: 'SC',
          type: 'text',
          isData: true
        },
        {
          id: 'total-miles',
          model: 'totalMiles',
          label: 'Total Miles',
          type: 'number',
          isData: true
        },
        {
          id: 'safety-driver',
          model: 'safetyDriver',
          label: 'SD',
          type: 'text',
          isData: true
        },
        {
          id: 'testId',
          model: 'id',
          label: 'Test ID',
          type: 'readonly',
          isData: false
        },
        {
          id: 'ses',
          model: 'ses',
          label: 'SE(s)',
          type: 'text',
          isData: true
        },
        {
          id: 'vehicle-id',
          model: 'vehicle_id',
          label: 'Vehicle ID',
          type: 'text',
          isData: false
        },
        {
          id: 'passengers',
          model: 'passengers',
          label: 'Passenger(s)',
          type: 'text',
          isData: true
        },
        {
          id: 'trailer-id',
          model: 'trailerId',
          label: 'Trailer Assignment',
          type: 'text',
          isData: true
        },
        {
          id: 'date',
          model: 'date',
          label: 'Date',
          type: 'date',
          isData: true
        },
        {
          id: 'location',
          model: 'location',
          label: 'Location',
          type: 'text',
          isRequired: true,
          isData: true
        },
        {
          id: 'time',
          model: 'time',
          label: 'Time',
          type: 'time',
          isData: true
        },
        {
          id: 'route',
          model: 'route',
          label: 'Route',
          type: 'text',
          isData: true
        },
        {
          id: 'laps-completed-per-route',
          model: 'lapsCompletedPerRoute',
          label: 'Number of laps completed per route',
          type: 'number',
          isData: true
        },
        {
          id: 'software-branch',
          model: 'softwareBranch',
          label: 'SW Branch',
          type: 'text',
          isData: true
        },
        {
          id: 'software-commit-hash',
          model: 'softwareCommitHash',
          label: 'SW Commit Hash',
          type: 'text',
          isData: true
        },
        {
          id: 'post-report-sign-off',
          model: 'scSignOff',
          label: 'SC Sign-off',
          type: 'text',
          isData: true
        }
      ]
    },
    {
      id: 'post-trip-report-did-you-section',
      headerSection: false,
      header: 'Did You?',
      fields: [
        {
          id: 'check-shore-power',
          model: 'shorePowerChecked',
          label: 'Plug in shore power?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-emo-button',
          model: 'emoButtonChecked',
          label: 'Engage EMO button?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-offloading',
          model: 'offloadingChecked',
          label: 'Ensure data is offloading in node room?',
          type: 'boolean',
          isData: true
        },
        {
          id: 'check-vehicle-secured',
          model: 'vehicleSecured',
          label: 'Ensure vehicle is secure?',
          type: 'boolean',
          isData: true
        }
      ]
    },
    {
      id: 'post-trip-report-info-section',
      headerSection: false,
      header: 'Report Information',
      subsections: [
        {
          id: 'test-description-sub-section',
          header: 'Test Description',
          fields: [
            {
              id: 'test-description',
              model: 'testDescription',
              type: 'text',
              isData: true
            }
          ]
        },
        {
          id: 'environmental-observations-sub-section',
          header: 'Environmental Observations (Weather, Road, etc.)',
          fields: [
            {
              id: 'environmental-observations',
              model: 'environmentalObservations',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'safety-debrief-sub-section',
          header: 'Safety Debrief',
          fields: [
            {
              id: 'felt-unsafe',
              model: 'feltUnsafe',
              type: 'radio',
              label: 'Did anyone feel unsafe?',
              choices: [
                {
                  id: 'felt-unsafe-yes',
                  value: true,
                  label: 'Yes'
                },
                {
                  id: 'felt-unsafe-no',
                  value: false,
                  label: 'No'
                }
              ],
              isRequired: true,
              isData: true
            },
            {
              id: 'feltUnsafeCategory',
              model: 'feltUnsafeCategory',
              label: 'Safety Category',
              type: 'radio',
              choices: [
                {
                  id: 'ADS - Lateral Control',
                  value: 'ADSLateralControl',
                  label: 'Lateral Control'
                },
                {
                  id: 'ADS - Longitudinal Control',
                  value: 'ADSLongitudinalControl',
                  label: 'Longitudinal Control'
                },
                {
                  id: 'Base Truck',
                  value: 'Base Truck',
                  label: 'Base Truck'
                },
                {
                  id: 'Weather/Traffic',
                  value: 'Weather/Traffic',
                  label: 'Weather/Traffic'
                },
                {
                  id: 'Other',
                  value: 'Other',
                  label: 'Other'
                }
              ],
              isRequired: false,
              isData: true
            },
            {
              id: 'felt-unsafe-explanation-safety-debrief',
              model: 'feltUnsafeExplanation',
              type: 'text',
              label: 'Please explain:',
              isData: true
            },
            {
              id: 'protocol-changes',
              model: 'protocolChanges',
              type: 'radio',
              label: 'Should we change anything about our safety protocols?',
              choices: [
                {
                  id: 'protocol-changes-yes',
                  value: true,
                  label: 'Yes'
                },
                {
                  id: 'protocol-changes-no',
                  value: false,
                  label: 'No'
                }
              ],
              isRequired: true,
              isData: true
            },
            {
              id: 'protocol-changes-explanation-safety-debrief',
              model: 'protocolChangesExplanation',
              type: 'text',
              label: 'Please explain:',
              isData: true
            }
          ]
        },
        {
          id: 'unplanned-disengagements',
          header: 'Summary of Unplanned Disengagements',
          fields: [
            {
              id: 'unplanned-takeovers',
              model: 'unplannedTakeovers',
              label: 'Number of unplanned takeovers:',
              type: 'number',
              isData: true
            },
            {
              id: 'unplanned-takeovers-description',
              model: 'unplannedTakeoversDesc',
              label: 'Summary of unplanned takeovers:',
              type: 'richtext',
              isData: true
            },
            {
              id: 'robotic-kickouts',
              model: 'rkos',
              label: 'Number of robotic kickouts:',
              type: 'number',
              isData: true
            },
            {
              id: 'rko-description',
              model: 'rkoDesc',
              label: 'Summary of RKOs:',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'safety-events',
          header: 'Safety Events',
          fields: [
            {
              id: 'safety-events',
              model: 'safetyEvents',
              label: 'Number of safety events:',
              type: 'number',
              isData: true
            },
            {
              id: 'safety-events-description',
              model: 'safetyEventsDesc',
              label: 'Summary of safety events:',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'feature-performance',
          header: 'Feature Performance Ratings and Comments',
          fields: [
            {
              type: 'info',
              message: `
                    <p>The rating scale for each feature is defined as follows:</p>
                    <ul>
                      <li>Unusable = consistent issues or disengagements, test had to be stopped</li>
                      <li>Poor = frequent issues or disengagements</li>
                      <li>Mediocre = occasional issues or disengagements</li>
                      <li>Good = few issues or disengagements, average human driver</li>
                      <li>Excellent = no issues or disengagements, exemplary driver</li>
                    </ul>
                    <p>If a feature was not used during this test, select N/A.</p>
                  `
            },
            {
              id: 'longitudinal-control',
              model: 'longitudinalControl',
              label: '<strong>Longitudinal Control</strong> (<em>brake or throttle performance, ACC</em>)',
              type: 'radio',
              isRequired: true,
              isData: true,
              choices: [
                {
                  id: 'longitudinal-control-notapplicable',
                  value: 0,
                  label: 'N/A'
                },
                {
                  id: 'longitudinal-control-unusable',
                  value: 1,
                  label: 'Unusable'
                },
                {
                  id: 'longitudinal-control-poor',
                  value: 2,
                  label: 'Poor'
                },
                {
                  id: 'longitudinal-control-mediocre',
                  value: 3,
                  label: 'Mediocre'
                },
                {
                  id: 'longitudinal-control-good',
                  value: 4,
                  label: 'Good'
                },
                {
                  id: 'longitudinal-control-excellent',
                  value: 5,
                  label: 'Excellent'
                }
              ]
            },
            {
              id: 'longitudinal-control-comments',
              model: 'longitudinalControlComments',
              type: 'richtext',
              isData: true
            },
            {
              id: 'lateral-control',
              model: 'lateralControl',
              label: `
                <strong>Lateral Control</strong>
                (<em>lane keeping, how well do we position ourselves in the lane, do we get too close to others,
                  do we maintain a consistent lane position,
                  any steering jerks, oscillations, or inappropriate biasing?</em>)
              `,
              type: 'radio',
              isRequired: true,
              isData: true,
              choices: [
                {
                  id: 'lateral-control-notapplicable',
                  value: 0,
                  label: 'N/A'
                },
                {
                  id: 'lateral-control-unusable',
                  value: 1,
                  label: 'Unusable'
                },
                {
                  id: 'lateral-control-poor',
                  value: 2,
                  label: 'Poor'
                },
                {
                  id: 'lateral-control-mediocre',
                  value: 3,
                  label: 'Mediocre'
                },
                {
                  id: 'lateral-control-good',
                  value: 4,
                  label: 'Good'
                },
                {
                  id: 'lateral-control-excellent',
                  value: 5,
                  label: 'Excellent'
                }
              ]
            },
            {
              id: 'lateral-control-comments',
              model: 'lateralControlComments',
              type: 'richtext',
              isData: true
            },
            {
              id: 'merges',
              model: 'merges',
              label: '<strong>Merges</strong> (<em>ending lanes, how well do we merge into traffic, too conservative or aggressive, is the lane movement smooth?</em>)',
              type: 'radio',
              isRequired: true,
              isData: true,
              choices: [
                {
                  id: 'merges-notapplicable',
                  value: 0,
                  label: 'N/A'
                },
                {
                  id: 'merges-unusable',
                  value: 1,
                  label: 'Unusable'
                },
                {
                  id: 'merges-poor',
                  value: 2,
                  label: 'Poor'
                },
                {
                  id: 'merges-mediocre',
                  value: 3,
                  label: 'Mediocre'
                },
                {
                  id: 'merges-good',
                  value: 4,
                  label: 'Good'
                },
                {
                  id: 'merges-excellent',
                  value: 5,
                  label: 'Excellent'
                }
              ]
            },
            {
              id: 'merges-comments',
              model: 'mergesComments',
              type: 'richtext',
              isData: true
            },
            {
              id: 'unforced-lane-changes',
              model: 'unforcedLaneChanges',
              label: '<strong>Unforced Lane Changes</strong> (<em>when passing vehicles, getting away from shoulder or merging vehicles</em>)',
              type: 'radio',
              isRequired: true,
              isData: true,
              choices: [
                {
                  id: 'unforced-lane-changes-notapplicable',
                  value: 0,
                  label: 'N/A'
                },
                {
                  id: 'unforced-lane-changes-unusable',
                  value: 1,
                  label: 'Unusable'
                },
                {
                  id: 'unforced-lane-changes-poor',
                  value: 2,
                  label: 'Poor'
                },
                {
                  id: 'unforced-lane-changes-mediocre',
                  value: 3,
                  label: 'Mediocre'
                },
                {
                  id: 'unforced-lane-changes-good',
                  value: 4,
                  label: 'Good'
                },
                {
                  id: 'unforced-lane-changes-excellent',
                  value: 5,
                  label: 'Excellent'
                }
              ]
            },
            {
              id: 'unforced-lane-changes-comments',
              model: 'unforcedLaneChangesComments',
              type: 'richtext',
              isData: true
            },
            {
              id: 'intersections',
              model: 'intersections',
              label: '<strong>Intersections</strong> (<em>traffic signal detection, how well do we stop and go, overall traversal, is the speed and trajectory appropriate?</em>)',
              type: 'radio',
              isRequired: true,
              isData: true,
              choices: [
                {
                  id: 'intersections-notapplicable',
                  value: 0,
                  label: 'N/A'
                },
                {
                  id: 'intersections-unusable',
                  value: 1,
                  label: 'Unusable'
                },
                {
                  id: 'intersections-poor',
                  value: 2,
                  label: 'Poor'
                },
                {
                  id: 'intersections-mediocre',
                  value: 3,
                  label: 'Mediocre'
                },
                {
                  id: 'intersections-good',
                  value: 4,
                  label: 'Good'
                },
                {
                  id: 'intersections-excellent',
                  value: 5,
                  label: 'Excellent'
                }
              ]
            },
            {
              id: 'intersections-comments',
              model: 'intersectionsComments',
              type: 'richtext',
              isData: true
            }
          ]
        },
        {
          id: 'overall-experience',
          header: 'Overall Experience',
          fields: [
            {
              id: 'why-concluded',
              model: 'whyConcluded',
              type: 'radio',
              label: `
                    <p>Why was the test concluded?</p>
                    <p>
                      <ul>
                        <li><em>Base truck or ADS hardware issues: Issues with the Base truck or ADS hardware</em></li>
                        <li><em>ODD limitations: ODD limitations (such as wind gusts)</em></li>
                        <li><em>Unsafe maneuver: Unsafe maneuver requires investigation</em></li>
                        <li><em>Poor ADS behavior: Generally poor ADS behavior</em></li>
                        <li><em>Abort criteria met: The hardware or software being tested failed; test met abort criteria</em></li>
                        <li><em>Success criteria met: The test criteria was met; successful test</em></li>
                      </ul>
                    </p>
                  `,
              choices: [
                {
                  id: 'why-concluded-truck-or-ads-issues',
                  value: 'truckOrAdsIssues',
                  label: 'Base truck or ADS hardware issues'
                },
                {
                  id: 'why-concluded-odd-limitations',
                  value: 'oddLimitations',
                  label: 'ODD limitations'
                },
                {
                  id: 'why-concluded-unsafe-maneuver',
                  value: 'unsafeManeuver',
                  label: 'Unsafe maneuver'
                },
                {
                  id: 'why-concluded-poor-ads-behavior',
                  value: 'poorAdsBehavior',
                  label: 'Poor ADS behavior'
                },
                {
                  id: 'why-concluded-failed',
                  value: 'abortCriteriaMet',
                  label: 'Abort criteria met'
                },
                {
                  id: 'why-concluded-success',
                  value: 'successCriteriaMet',
                  label: 'Success criteria met'
                }
              ],
              isRequired: false,
              isData: true
            },
            {
              id: 'experience-rating',
              model: 'experienceRating',
              type: 'radio',
              label: 'Rate the overall experience of the test:',
              choices: [
                {
                  id: 'experience-rating-aborted-before-leaving',
                  value: 'abortedBeforeLeaving',
                  label: 'Aborted before leaving base'
                },
                {
                  id: 'experience-rating-aborted-criteria-failed',
                  value: 'abortedCriteriaFailed',
                  label: 'Aborted before test criteria met'
                },
                {
                  id: 'experience-rating-poor',
                  value: 'poor',
                  label: 'Poor but manageable'
                },
                {
                  id: 'experience-rating-decent',
                  value: 'decent',
                  label: 'Decent but functional'
                },
                {
                  id: 'experience-rating-good',
                  value: 'good',
                  label: 'Good'
                },
                {
                  id: 'experience-rating-excellent',
                  value: 'excellent',
                  label: 'Excellent'
                }
              ],
              isRequired: false,
              isData: true
            }
          ]
        },
        {
          id: 'comments-concerns',
          header: 'Other Comments/Concerns',
          fields: [
            {
              id: 'ticket-questions',
              model: 'ticketQuestions',
              label: 'Answers to any questions in the test ticket:',
              type: 'richtext',
              isData: true
            },
            {
              id: 'persistent-critical-issues',
              model: 'persistentCriticalIssues',
              type: 'radio',
              label: 'Were there persistent and/or critical issues identified from this test that should be communicated to Safety, Engineering, or Operations?',
              choices: [
                {
                  id: 'persistent-critical-issues-yes',
                  value: true,
                  label: 'Yes'
                },
                {
                  id: 'persistent-critical-issues-no',
                  value: false,
                  label: 'No'
                }
              ],
              isRequired: true,
              isData: true
            },
            {
              id: 'persistent-critical-issues-comments',
              model: 'persistentCriticalIssuesComments',
              type: 'textarea',
              isData: true
            },
            {
              id: 'has-truck-issues',
              model: 'hasTruckIssues',
              type: 'radio',
              label: 'Were there hardware, sensor, or base truck issues?',
              choices: [
                {
                  id: 'truck-issues-yes',
                  value: true,
                  label: 'Yes'
                },
                {
                  id: 'truck-issues-no',
                  value: false,
                  label: 'No'
                }
              ],
              isRequired: true,
              isData: true
            },
            {
              id: 'truck-issues',
              model: 'truckIssues',
              type: 'richtext',
              isData: true
            },
            {
              id: 'additional-comments',
              model: 'comments',
              label: 'Additional comments, lessons learned, or anything else that went well?',
              type: 'richtext',
              isData: true
            }
          ]
        }
      ]
    }
  ]
};
