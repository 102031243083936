import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import Login from '@/components/Login.vue';
import TestInfo from '@/views/TestInfo.vue';
import Tests from '@/views/Tests.vue';
import Forbidden from '@/views/Forbidden.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Tests,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tests/:testId',
      component: TestInfo,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tests',
      component: Tests,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/forbidden/:testId',
      name: 'forbidden',
      component: Forbidden,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/login/callback',
      component: LoginCallback
    }
  ]
});

router.beforeEach(navigationGuard);

export default router;
